<template>
    <div>
        <IndexBar class="indexBar" :index-list="FirstPin" :sticky="false" highlight-color="#fb6463">
            <div v-for="(item,index) in firstName" :key="index">
                <IndexAnchor :index="index">
                    <span class="indexWord">{{index}}{{index=='定位' || index=='热门'?'城市':''}}</span>
                </IndexAnchor>
                <div class="hotcity" v-if="index=='定位'">
                    <div class="item">
                        <span>重新获取定位</span>
                    </div>
                </div>
                <!--
                <div class="hotcity" v-if="index=='热门'">
                    <div class="item" v-for="(citem,cindex) in hotcity" :key="cindex">
                        <span @click="chooseCity(citem)">{{citem.nm}}</span>
                    </div>
                </div>-->
                <cell @click="chooseCity(citem)" v-for="(citem,cindex) in item" :key="cindex" :title="citem" v-if="index!='定位' || index!='热门'"/>
            </div>

        </IndexBar>
    </div>
</template>

<script>
    import wx from "weixin-js-sdk";
    import pinyin from 'js-pinyin';
    import { getRegion } from "@api/tasting";
    import {IndexBar, IndexAnchor, Cell, Col, Row, Toast} from 'vant';
    import city from "./../../utils/city";
    export default {
        name: "Cityselect",
        data () {
            return {
                hotcity:[
                    {
                        "id": 0,
                        "nm": "全国",
                        "py": "quanguo"
                    },
                    {
                        "id": 1,
                        "nm": "北京",
                        "py": "beijing"
                    },
                    {
                        "id": 10,
                        "nm": "上海",
                        "py": "shanghai"
                    },
                    {
                        "id": 20,
                        "nm": "广州",
                        "py": "guangzhou"
                    },
                    {
                        "id": 30,
                        "nm": "深圳",
                        "py": "shenzhen"
                    },
                    {
                        "id": 57,
                        "nm": "武汉",
                        "py": "wuhan"
                    },
                ],
                city: city.cts,
                FirstPin: ["定位", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "W", "X", "Y", "Z"],
                firstName: [],
            }
        },
        components: {
            IndexBar, IndexAnchor, Cell, Col, Row
        },
        mounted: function() {
            wx.miniProgram.getEnv(function(res){
                wx.getLocation({
                    type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        console.log(res.latitude, res.longitude)
                        var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                        var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                        var speed = res.speed; // 速度，以米/每秒计
                        var accuracy = res.accuracy; // 位置精度
                    }
                });
            })
            
            this.getRegion();
            // let firstName = {};
            // this.FirstPin.forEach((item)=>{
            //     firstName[item] = [];
            //     this.city.forEach((el)=>{
            //         let first = el.py.substring(0,1).toUpperCase();
            //         if( first == item ){
            //             firstName[item].push(el.nm)
            //         }
            //     })
            // })
            // this.firstName=firstName;
        },
        methods: {
            getRegion: function() {
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                getRegion().then(res => {
                    Toast.clear();
                    let city = res.data;
                    let firstName = {};
                    this.FirstPin.forEach((item)=>{
                        firstName[item] = [];
                        pinyin.setOptions({checkPolyphone: false, charCase: 0});
                        city.forEach((el)=>{
                            el.nm = el.name;
                            el.py = pinyin.getFullChars(el.name);
                            let first = el.py.substring(0,1).toUpperCase();
                            if( first == item ){
                                firstName[item].push(el.nm)
                            }
                        })
                    })
                    this.firstName=firstName;
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            chooseCity: function() {

            }
        }
    }
</script>
<style scoped lang="scss">
.hotcity{
    overflow: hidden;
    .item{
        display: block;text-align: center;line-height: 0.6rem;width: 33.333%;float: left;padding: 8px 0.2rem;
        span{background: #fff;display: block;border: 1px solid #eee;}
    }
}
</style>